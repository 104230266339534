// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-about-me-world-tsx": () => import("./../../../src/templates/about-me-world.tsx" /* webpackChunkName: "component---src-templates-about-me-world-tsx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-archive-overview-tsx": () => import("./../../../src/templates/archive-overview.tsx" /* webpackChunkName: "component---src-templates-archive-overview-tsx" */),
  "component---src-templates-archive-world-tsx": () => import("./../../../src/templates/archive-world.tsx" /* webpackChunkName: "component---src-templates-archive-world-tsx" */),
  "component---src-templates-show-tsx": () => import("./../../../src/templates/show.tsx" /* webpackChunkName: "component---src-templates-show-tsx" */),
  "component---src-templates-upcoming-tsx": () => import("./../../../src/templates/upcoming.tsx" /* webpackChunkName: "component---src-templates-upcoming-tsx" */),
  "component---src-templates-upcoming-world-tsx": () => import("./../../../src/templates/upcoming-world.tsx" /* webpackChunkName: "component---src-templates-upcoming-world-tsx" */),
  "component---src-templates-video-tsx": () => import("./../../../src/templates/video.tsx" /* webpackChunkName: "component---src-templates-video-tsx" */)
}

